import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Page404 from '../views/404.vue'
import Trade from '../views/Trade2.vue'
import Wallet from '../views/Wallet.vue'
import Sniper from '../views/Sniper.vue'
import Launchpad from '../views/Launchpad.vue'
import LaunchpadDetail from '../views/LaunchpadDetail.vue'
import History from '../views/History.vue'
import Analytic from '../views/Analytic.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  { path: "*", component: Page404 },
  {
    path: '/sniper',
    name: 'Sniper',
    component: Sniper
  },
  {
    path: '/history',
    name: 'History',
    component: History
  },
  {
    path: '/launchpad',
    name: 'Launchpad',
    component: Launchpad
  },
  {
    path: '/launchpad/:id',
    name: 'LaunchpadDetail',
    component: LaunchpadDetail
  },
  
  {
    path: '/trade/:token',
    name: 'Trade',
    component: Trade
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet
  }, {
    path: '/analytic',
    name: 'Analytic',
    component: Analytic
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
