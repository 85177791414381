<template>
    <div class="table-responsive">
        <div class="card"  style="background-color: #013a63;">
            <div class="card-body table-responsive">
                <table class="table table-hover table-borderless">
            <thead class="thead-dark text-white" >
                <th></th>
                <th>{{ token.name }}</th>
                <th>Price $</th>
                <th>Value</th>
                <th>Dex </th>
                <th v-if="address">%</th>
                <th>Time</th>
                <th></th>
            </thead>
            <tbody class="text-white">
                <tr v-for="swap in histories" :key="swap.logId"  class="text-white">
                    <td :class="{ 'text-warning': swap.transactionType == 'BUY', 'text-danger': swap.transactionType == 'SELL'}" >{{ swap.transactionType }}</td>
                    <td>{{ usd(swap.tokenAmount) }}</td>
                    <td>{{ usd(swap.price) }}$</td>
                    <td>{{ usd(swap.value) }}$</td>
                    <td>{{ dex(swap.factory) }}</td>
                    <td v-if="address" :class="{ 'text-warning': between(swap.price, token.priceUSD) >= 0, 'text-danger': between(swap.price, token.priceUSD) < 0}">{{ between(swap.price, token.priceUSD).toFixed(0) }} %</td>
                    <td >{{ ago(swap.timestamp * 1000) }}</td>
                    <td><a style="color: white;text-decoration: none;" target="_blank" :href="`//bscscan.com/tx/${swap.transactionHash}`"><i class="fa fa-search"/></a></td>
                </tr>
            </tbody>
        </table>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment'

export default {
    props: ['address', 'token'],
    data: () => ({
        histories: [],
        pairDetail: {},
        interval: null,
    }),
    methods: {
        between: function (num1, num2) {
            return ((num2 - num1) / num1 * 100);
        },
        usd: function (value) {
            return (value).toLocaleString(undefined, {minimumFractionDigits: 10})
        },
        dex: function (factoryAddress) {
            if(factoryAddress == '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73') {
                return 'PcsV2'
            }
            if(factoryAddress == '0xBCfCcbde45cE874adCB698cC183deBcF17952812') {
                return 'PcsV1'
            }
            if(factoryAddress == '0x0841BD0B734E4F5853f0dD8d7Ea041c241fb0Da6') {
                return 'Apeswap'
            }
            return 'Unkown'
        },
        usdValue: function (swap) {
            let decimalsLeft = 0
            if(this.pairDetail.token0.symbol == "WBNB") {
                decimalsLeft = this.pairDetail.token0.decimals - this.pairDetail.token1.decimals
            } else {
                decimalsLeft = this.pairDetail.token1.decimals - this.pairDetail.token0.decimals
            }
            let usd = swap.usd0
            
            if(this.pairDetail.token0.address == "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c") {
                usd =  swap.usd1
            }
             let usdReal = (usd / ( 10 ** decimalsLeft))
             return  (usdReal).toFixed((usdReal > 0.9 ? 2 : 18))
        },
        bnbValue: function (swap) {
            let value = value =  (swap.amount1Out == 0 ? swap.amount1In : swap.amount1Out)
            if(this.pairDetail.token0.address == "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c") {
                value =  (swap.amount0Out == 0 ? swap.amount0In : swap.amount0Out)
             } 
             return value / 1e18
        },
        tokenValue: function (swap) {
            let value = value =  (swap.amount1Out == 0 ? swap.amount1In : swap.amount1Out)
            let decimals = this.pairDetail.token1.decimals
            if(this.pairDetail.token0.address != "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c") {
                value =  (swap.amount0Out == 0 ? swap.amount0In : swap.amount0Out)
                decimals = this.pairDetail.token0.decimals
             } 
             return value / (10 ** decimals)
    
        },
        txType: function (swap) {
             if(this.pairDetail.token0.address == "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c") {
                return (swap.amount1Out == 0)
             } else {
                 return (swap.amount0Out == 0)
             }
        },
        ago: function (time) {
            return moment(time).fromNow()
        },
        loadData: async function () {
            let result = await this.axios.get(`token/${this.token.address}/history?wallet=${this.$parent.$parent.client.utils.toChecksumAddress(this.address)}`)
            this.histories = result.data.data
            this.pairDetail = result.data.pair


        }
    },
    destroyed: function () {
        clearInterval(this.interval)
    },
    mounted: function () {
        this.interval = setInterval(this.loadData, 10000);
        setTimeout(this.loadData, 1000)
    }
}
</script>