<template>
  <div>
    <div>
      <div class="row">
     
        <div class="col-md-6 mb-2">
          <button class="btn btn-light me-2" @click="price = !price; renderChart()"><i
          class="fa fa-exchange-alt"></i></button>
      <b-button-group>
        <button class="btn btn-outline-light" @click="interval = 'minutely'; getChart()"
          :class="{ 'btn-light text-dark': interval == 'minutely'}">Minutely</button>
        <button class="btn btn-outline-light" @click="interval = 'hourly';  getChart()"
          :class="{ 'btn-light  text-dark': interval == 'hourly'}">Hourly</button>
        <button class="btn btn-outline-light" @click="interval = 'daily'; getChart()"
          :class="{ 'btn-light  text-dark': interval == 'daily'}">Daily</button>
      </b-button-group>
        </div>
      </div>




      <apexchart :type="type" id="chart" height="500" ref="chart" :options="chartOptions" :series="series" v-if="status == 'loaded'"
        :key="chartKey">
      </apexchart>

    </div>
    <div v-if="status == 'loading'">
      <center class="mt-5">
        <div class="spinner-grow text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <h5 class="text-muted">Loading</h5>
      </center>
    </div>
    <div v-if="status == 'error'">
      <center class="mt-5" @click="getChart()" style="cursor: pointer;">
        <i class="fa fa-chart-bar fa-9x"></i>
        <h5 class="text-muted">Chart Not Available</h5>
      </center>
    </div>
  </div>
</template>
<script>
  import VueApexCharts from 'vue-apexcharts'
  export default {
    props: ['pair','token'],
    components: {
      apexchart: VueApexCharts,
    },
    data: () => ({
      interval1: null,
      interval2: null,
      price: true,
      type: 'line',
      interval: 'daily',
      status: 'loading',
      chartKey: true,
      chart_raw: [],
      priceNative: 0,
      series: [{
        data: []
      }],
      chart: {
        scale: '',
      },
      chartOptions: {
        xaxis: {
          type: 'category',
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        chart: {
          type: 'candlestick',
          height: 290,
          id: 'candles',
          toolbar: {
            autoSelected: 'pan',
            show: true
          },
          zoom: {
            enabled: true
          },
        },
        plotOptions: {
          candlestick: {
            colors: {
              upward: '#3C90EB',
              downward: '#DF7D46'
            }
          }
        }
      },
    }),
    computed: {
      priceUSD: function () {
        return this.priceNative * this.$parent.$parent.BNBPrice
      }
    },
    methods: {
      renderChart: async function () {
        let data = []
        let that = this

        this.charts_raw.forEach((chart) => {
          let y = [chart.open0, chart.high0, chart.low0, chart.close0]
          if (that.price) y = [chart.open1, chart.high1, chart.low1, chart.close1]
          data.push({
            x: chart['_id'],
            y: y
          })
        })
        this.$refs.chart.updateSeries([{data: data, name: 'price'}], false, true)

        // this.chartKey = !this.chartKey
        this.$forceUpdate()
      },
      getChart: async function () {
        try {
          let charts = await this.axios.get(`pair/${this.pair}/chart?interval=${this.interval}`)
          this.charts_raw = charts.data.data
          if (charts.data.pair.token0.symbol != "WBNB") {
            this.price = false
          }
          this.charts_raw.sort((a,b) => a.timestamp - b.timestamp)
          this.status = 'loaded'
          this.renderChart()

        } catch (e) {
          this.status = 'error'
        }

      },
    },
    destroyed: function () {
      clearInterval(this.interval1)
    },
    mounted: function () {
      this.status = 'loaded'
      this.interval1 = setInterval(this.getChart, 60000)
      this.getChart()
    }
  }
</script>