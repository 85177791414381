<template>
    <div>
        <div class="card" style="background-color: #013a63;" v-if="$parent.$parent.address">
            <div class="card-body text-white shadow">
                <button class="btn btn-light float-start" @click="changeSwap"><i class="fa fa-exchange-alt"></i></button><h5 class="fw-bold ms-5 mt-2">{{( from == 'BNB' ? 'Buy' : 'Sell')}}</h5>
                <!-- <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link" :class="{ 'active': swapType == 'market'}" @click="swapType == 'market'"
                            aria-current="page" href="#">Market</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Limit</a>
                    </li>
                </ul> -->
                <div class="alert alert-warning mt-3" v-if="!tradeable">This Token May Not Be Tradeable</div>
                <br/>
                <label>{{ token.name }}</label>
                <label class="float-end text-muted" style="cursor:pointer;" @click="tokenAmount = (token.balance == 0 ? 0 : token.balance / (10 ** token.decimals)).toFixed(3); tokenAmountChange()">Balance: {{(token.balance == 0 ? 0 : token.balance / (10 ** token.decimals)).toFixed(3) }}</label>
                <input class="form-control" @change="tokenAmountChange" v-model="tokenAmount" placeholder="0">
                
                <br />
                <label>BNB</label>
                <label class="float-end text-muted" @click="bnbAmount = $parent.$parent.balance / 1e18">Balance: {{($parent.$parent.balance / 1e18).toFixed(3) }}</label>
                <input class="form-control" @change="bnbAmountChange" v-model="bnbAmount" placeholder="0">
                <br />
                <div class="alert alert-info">
                    <small v-if="from == 'BNB'">
                        Swap BNB {{bnbAmount}}  To {{token.name}} {{tokenAmount}} 
                    </small>
                    <small v-if="from != 'BNB'">
                        Swap {{token.name}} {{tokenAmount}} To BNB {{bnbAmount}}
                    </small> 
                </div>
                                <small>Instant Buy/Sell</small>
                <div class="d-grid gap-2">
                <button v-if="needApproval" class="btn btn-outline-light" @click="approve">Approve</button>
                <button class="btn btn-light text-bold" :disabled="needApproval" @click="swap"><b>Swap</b></button>
                </div>
            </div>
        </div>
        <div class="card" v-else>
            <div class="card-body">
                <h5>Please Connect Your Wallet</h5>
                
            </div>
        </div>
    </div>
</template>

<script>
import erc from '../utils/erc'
import uniswap from '../utils/uniswap'
import abi from '../utils/abi'
import { MaxUint256 } from '@ethersproject/constants'

export default {
    props: ['address'],
    data: () => ({
        slipage: 1,
        ROUTER: null,
        FACTORY: null,
        tokenContract: null,
        price: 0,
        pairs: [],
        tradeable: true,
        needApproval: false,
        bnbAmount: '',
        tokenAmount: '',
        selectedMarket: {},
      
        token: {
            address: ''
        },
        trade: {
            status: 'found'
        },
        myTokens: [],
        swapType: 'market',
        from: 'BNB', // BNB OR TOKEN,
        
    }),
    methods: {
        approve: async function () {
            this.tokenContract.methods.approve(this.selectedMarket.router, MaxUint256).send({from: this.$parent.$parent.address})
        },
        swap: async function () {
            console.log(this.$parent.$parent.address)
            if(this.from == 'BNB') {
                let minAmount = (this.slipage == 0 ? 1 : 1)
                this.ROUTER.methods.swapExactETHForTokens(
                    minAmount,
                    ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', this.$router.currentRoute.params.token],
                    this.$parent.$parent.address,
                    Number.parseInt(Date.now() / 1000) + 60
                ).send({from: this.$parent.$parent.address, value: this.bnbAmount * 1e18})
            } else {
                let minAmount = (this.slipage == 0 ? 1 : 1)
                this.ROUTER.methods.swapExactTokensForETH(
                    (Number.parseFloat(this.tokenAmount) * (10 ** this.token.decimals)).toString(), 
                    minAmount, 
                    [this.$router.currentRoute.params.token, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'],
                    this.$parent.$parent.address,
                    Number.parseInt(Date.now() / 1000) + 60
                    ).send({from: this.$parent.$parent.address})
            }
        },
        tokenAmountChange: function () {
            this.bnbAmount = this.tokenAmount * this.price
            this.checkApproved()
        },
        bnbAmountChange: function () {
            this.tokenAmount = parseFloat(this.bnbAmount) / this.price 
        },
        checkApproved: async function () {
            if(this.from != "BNB") {
                let approved = await this.tokenContract.methods.allowance(this.$parent.$parent.address,this.selectedMarket.router).call()
                if(parseInt(approved) >= this.token.balance) {
                    this.needApproval = false
                } else {
                    this.needApproval = true
                }
            } else {
                this.needApproval = false
            }
        },
        getPrice: async function () {
            this.price = await uniswap.getTokenPriceNative(this.$router.currentRoute.params.token, this.$parent.$parent.client)
        },
        changePair: function() {
            this.ROUTER = new this.$parent.$parent.client.eth.Contract(abi.ROUTER_ABI, this.selectedMarket.router)
            this.FACTORY = new this.$parent.$parent.client.eth.Contract(abi.FACTORY_ABI, this.selectedMarket.factory)
            this.$emit('pairChange', this.selectedMarket.pair)
        },
        getPair: async function () {
            this.pairs = await uniswap.getPairs(this.$router.currentRoute.params.token, this.$parent.$parent.client)
            if(this.pairs.length > 0) {
                this.selectedMarket = this.pairs[0]
                this.changePair()
            } else {
                this.$emit('noPair', true)
                setTimeout(this.getPair, 3000)
            }
        },
        checkTradeable: async function () {
            let check_tradeable = await this.axios.get(`/token/${this.address}`)
            this.tradeable = check_tradeable.data.tradeable
        },
        getInfo: async function() {
            let [name,symbol, decimals, totalSupply] = await erc.info(this.address, this.$parent.$parent.client)
            let balance = await erc.balance(this.address, this.$parent.$parent.address, this.$parent.$parent.client)
            this.token = {
                name,symbol,decimals,totalSupply,balance
            }

            this.$forceUpdate()
        },
        changeSwap: function () {
            this.from = (this.from == 'BNB' ? this.$router.currentRoute.params.token : 'BNB')
            this.checkApproved()
        },
        init: function () {
            if(this.$parent.$parent.address == null) {
                setTimeout(this.init, 500)
                return;
            }

            this.getInfo()
            setInterval(this.getInfo, 3000)
            this.getPrice()
            this.checkTradeable()
            this.tokenContract =  erc.contract(this.$router.currentRoute.params.token, this.$parent.$parent.client)
                
            setInterval(this.checkApproved, 3000)
            setInterval(this.getPrice, 3000)
        }
    },
    mounted: function () {
                    this.getPair()
        this.init()
    }
}
</script>