<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <div class="float-end">
                            <input type="checkbox"> My Snipe Only
                        </div>
                        <table class="table table-borderless mt-3">
                            <thead>
                                <th>ID</th>
                                <th>Token</th>
                                <th>Amount</th>
                                <th>Status</th>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <h5>Sniper</h5>

                        <label>Token Address</label>
                        <input class="form-control" placeholder="0x" v-model="tokenAddress" @change="getToken" />
                        <div class="card mt-2" v-if="token != null">
                            <div class="card-body bg-info text-white fw-bold mb-2">
                                Token Name: {{ token.name }} <br/>
                                Token Symbol: {{ token.symbol }} <br/>
                                Token Supply : {{ token.totalSupply / 10 ** token.decimals }} <br/>
                            </div>
                        </div>
                        <div class="mt-3">
                            <label>BNB Amount</label>
                            <label class="float-end">{{ $parent.balance / 1e18 }} </label>
                            <input class="form-control" placeholder="" v-model="amount" />
                        </div>

                        <button class="btn btn-primary btn-block btn-lg mt-3">Submit</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import erc from '../utils/erc'
export default {
    data: () => ({
        tokenAddress: '',
        token: null,
        amount: 0
    }),
    methods: {
        getToken: async function () {
            let [name,symbol,decimals,totalSupply ] = await erc.info(this.tokenAddress, this.$parent.client)
            this.token = {
                name,symbol,decimals,totalSupply
            }
        },
    }
}
</script>