<template>
  <div>
      <h4 class="text-white">Blockchain Information Realtime For You</h4>
      <h6 class="text-muted">One Place For Many Defi Feature </h6>
      <button class="btn  btn-sm mt-5 mb-2" :class="{ 'btn-outline-dark shadow-sm  text-white': selected == 'top_pair', 'btn-dark': selected != 'top_pair'}"  @click="selected = 'top_pair'" style="border-redius: 10px !important;"><i class="fa fa-fire"></i> Trending</button>
      <button class="btn btn-dark btn-sm ms-1 mt-5 mb-2" :class="{ 'btn-outline-dark shadow-sm  text-white': selected == 'watchlist'}" @click="selected = 'watchlist'" style="border-redius: 10px !important;"><i class="fa fa-eye"></i> Watchlist</button>
      <div v-if="selected == 'top_pair'">
      <div class="card bg-dark mt-2 text-white shadow-sm border-white" v-for="pair in $parent.bnb_pair" :key="pair.pair.address" style="cursor: pointer;" @click="trade(pair)">
          <div class="card-body">
              <div class="row">
                <div class="col-md-1">
                  <img v-if="pair.pair.token0.symbol == 'WBNB'" onerror="this.src = '/token.webp';" :src="`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${$parent.client.utils.toChecksumAddress(pair.pair.token1.address)}/logo.png`"
                                      height="48" class="rounded-circle me-1">
                  <img v-if="pair.pair.token1.symbol == 'WBNB'" onerror="this.src = '/token.webp';" :src="`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${$parent.client.utils.toChecksumAddress(pair.pair.token0.address)}/logo.png`"
                                      height="48" class="rounded-circle me-1">
                                 
                </div>
                <div class="col-md-4">
                  <h6 v-if="pair.pair.token0.symbol == 'WBNB'">{{ pair.pair.token1.name }}</h6>
                  <h6 v-if="pair.pair.token1.symbol == 'WBNB'">{{ pair.pair.token0.name }}</h6>
                  <p class="text-muted">${{ usd(pair) }}</p>
                </div>
              </div>
              
          </div>
        </div>
      </div>
      <div class="card" style="background-color: black;" v-if="selected == '1'">
        <div class="card-body table-responsive">
        
          <table class="table table-hover mt-2" @v-if="selected == 'top_pair'">
            <thead class="text-white">
              <th>#</th>
              <th>Name</th>
              <th>Price ($)</th>
            </thead>
            <tbody>
              <tr v-if="$parent.bnb_pair.length == 0">
                <td colspan="5" class="text-white" style="border: none !important;">
                  <center>
                    Gathering Data...
                  </center>
                </td>
              </tr>
              <tr class="text-white" v-for="(pair, index) in $parent.bnb_pair" :key="pair.pair.address" style="cursor: pointer;" @click="trade(pair)">
                <td class="mb-2">{{ index + 1 }}</td>
                <td v-if="pair.pair.token0.symbol == 'WBNB'">
                   <img onerror="this.src = '/token.webp';"
                                        :src="`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${$parent.client.utils.toChecksumAddress(pair.pair.token1.address)}/logo.png`"
                                        height="24" class="rounded-circle me-1">
                                        {{ pair.pair.token1.name }} </td>
                <td v-if="pair.pair.token1.symbol == 'WBNB'">
                  <img onerror="this.src = '/token.webp';"
                                        :src="`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${$parent.client.utils.toChecksumAddress(pair.pair.token0.address)}/logo.png`"
                                        height="24" class="rounded-circle me-1">
                  {{ pair.pair.token0.name }}</td>
                <td>{{ usd(pair) }}</td>

        
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card" style="background-color: black;" v-if="selected == 'watchlist'">
        <div class="card-body table-responsive">
          <table class="table table-hover mt-2">
            <thead class="text-white">
              <th>Name</th>
            </thead>
            <tbody>
              <tr v-if="Object.keys(watchlists).length == 0">
                <td colspan="5" class="text-white" style="border: none !important;">
                  <center>
                    Start Using This Feature By Click Watchlist Button
                  </center>
                </td>
              </tr>
              <tr class="text-white" v-for="(token, index) in watchlists" :key="token.address" style="cursor: pointer;" @click="$router.push(`/trade/${index}`)">

                <td>
                  <img onerror="this.src = '/token.webp';"
                                        :src="`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${$parent.client.utils.toChecksumAddress(index)}/logo.png`"
                                        height="24" class="rounded-circle me-1">
                  {{ token.name }}</td>
  

        
              </tr>
            </tbody>
          </table>
        </div>
      </div>


  </div>
</template>

<script>
  export default {
    data() {
      return {
        // Note `isActive` is left out and will not appear in the rendered table
        address: '',
        selected: 'top_pair',
        watchlists: {},
      }
    },
    methods: {
      loadWatchlist: function () {
        let watchlists = window.localStorage.getItem('whitelist')
        if (watchlists == null) {
          watchlists  = {}
        } else {
          watchlists = JSON.parse(watchlists)
        }
        this.watchlists = watchlists
      },
      usd: function (pair) {
          let decimalsLeft = 0
          if(pair.pair.token0.symbol == "WBNB") {
            decimalsLeft = pair.pair.token0.decimals - pair.pair.token1.decimals
          } else {
            decimalsLeft = pair.pair.token1.decimals - pair.pair.token0.decimals
          }
          let price = (pair.price_native / (10 ** decimalsLeft) * this.$parent.BNBPrice)
          return (price).toFixed((price > 0.1 ? 2 : 18))
      },
      trade: function (history) {
            this.$router.push(`/trade/${(history.pair.token0.address == "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" ? history.pair.token1.address: history.pair.token0.address)}`)
      }
    },
    mounted: function () {
      document.title = "Binance Smart Chain Data, Chart | fliper.io"
      this.loadWatchlist()
    }
  }
</script>