<template lang="pug">
    div.container-fluid
        div.row
            div.col-md-4
                div.card
                    div.card-body
                        h5.card-title Select Chart
                        button.btn.btn-outline-primary Top Traded Pair
            div.col-md-8
                div.card
                    div.card-body
</template>

<script>
export default {
    data: () => ({

    }),
    methods: {
        showMostTradedPair: async function () {
        }
    },
    mounted: async function () {

    }
}
</script>