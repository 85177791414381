<template>
    <div>
        <h5 class="text-white">Connect Your Wallet</h5>
        <button class="btn btn-warning mt-2 w-25" @click="$parent.$parent.connectMetamask"><img src="@/assets/metamask.svg" height="32" width="32"> Metamask</button>

    </div>
</template>


<script>
export default {
    
}
</script>