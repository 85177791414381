<template>
  <div id="app"
    style="max-width: 100vw;overflow-x:auto;background: #14213d;">

    <div style="min-height: 100vh;">
      <div class="row">
        <div class="col-md-1">
          <div class="d-flex flex-column flex-shrink-0 border-right bar">
            <ul class="nav nav-pills nav-flush flex-column mb-auto text-center mt-5 pt-5 nav-bar">
              <li class="nav-item">
                <a @click="$router.push('/')"  class="nav-link py-3" href="javascript:void(0)" :class="{'text-white': $router.currentRoute.name == 'Home', 'text-muted': $router.currentRoute.name != 'Home'}" aria-current="page" title="Home"
                  data-bs-toggle="tooltip" data-bs-placement="right">
                   <i class="fal fa-chart-bar fa-2x"></i>
                   <br/>
                   Home
                   
                   
                </a>
              </li>
              <li>
                <a @click="$router.push('/trade/0xe9e7cea3dedca5984780bafc599bd69add087d56')"  class="nav-link py-3" href="javascript:void(0)"  :class="{'text-white': $router.currentRoute.name == 'Trade', 'text-muted': $router.currentRoute.name != 'Trade'}" title="Dashboard" data-bs-toggle="tooltip"
                  data-bs-placement="right">
                  <i class="fal fa-exchange fa-2x"></i>
                  <br/>
                   Trade
                </a>
              </li>
              <li>
                <a @click="$router.push('/history')" class="nav-link py-3 "  href="javascript:void(0)" :class="{'text-white': $router.currentRoute.name == 'History', 'text-muted': $router.currentRoute.name != 'History'}" title="Orders" data-bs-toggle="tooltip"
                  data-bs-placement="right">
                  <i class="fal fa-history fa-2x"></i>
                  <br/>
                   History
                </a>
              </li>
              <li>
                <a @click="$router.push('/wallet')" class="nav-link py-3 " href="javascript:void(0)"  :class="{'text-white': $router.currentRoute.name == 'Wallet', 'text-muted': $router.currentRoute.name != 'Wallet'}" title="Products" data-bs-toggle="tooltip"
                  data-bs-placement="right">
                  <i class="fal fa-wallet fa-2x"></i>
                  <br/>
                   Wallet
                </a>
              </li>
            </ul>
            <div class="dropdown border-top d-none d-md-block">
              <a href="#"
                class="d-flex align-items-center justify-content-center p-3 ms-2 link-dark text-decoration-none dropdown-toggle"
                id="dropdownUser3" data-bs-toggle="dropdown" aria-expanded="false">
                <img :src="`https://avatars.dicebear.com/api/identicon/${this.address}.svg`" alt="mdo" width="32" height="32" class="rounded-circle">
              </a>
              <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser3">
                <li><a class="dropdown-item" href="#">{{ renderAddress() }}</a></li>
                
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li><a class="dropdown-item" href="#" @click="connectMoralis()">Connect</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-11 mt-5 mb-5">
          <div class="container-fluid">
            <router-view />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5">

    </div>
  </div>
</template>

<style>
  #app {
    font-family: 'Inter', sans-serif;
  }
</style>

<script>
  import Web3 from 'web3'
  import Moralis from 'moralis'
  import Uniswap from './utils/uniswap'
  export default {
    data: () => ({
      currentBlock: 0,
      address: null,
      balance: 0,
      client: null,
      BNBPrice: 0,
      most_traded_pair: []
    }),
    computed: {
      bnb_pair: function () {
        return this.most_traded_pair.filter((pair) => pair.pair.token0.symbol == "WBNB" || pair.pair.token1.symbol ==
          "WBNB")
      },
    },
    methods: {

      getBNBPrice: async function () {
        try {

          this.BNBPrice = await Uniswap.getNativePrice(this.client)
          this.$forceUpdate()
          setTimeout(this.getBNBPrice, 10000)
        } catch (error) {
          console.log(error)
        }
      },
      renderAddress: function () {
        if (this.address == null) return ''
        return '0x' + this.$data.address.slice(0, 5) + '...' + this.$data.address.slice(this.$data.address.length - 5,
          this.$data.address.length)
      },
      hotPair: async function () {
        let data = await this.axios.get('/analytic/most_traded_pair')
        this.most_traded_pair = data.data.data
        // let that = this
        // this.most_traded_pair.forEach(async function (pair, index) {
        //     try {
        //         let address = (pair.pair.token0.symbol == "WBNB" ? pair.pair.token1.address : pair.pair.token0.address)
        //         console.log(address)
        //         that.most_traded_pair[index].price_native = await Uniswap.getTokenPriceNative(address, that.client)
        //         that.$forceUpdate()
        //     } catch (error) {
        //         console.log(error)
        //     }

        // })
      },
      connectMoralis: async function () {
        let that = this
                      console.log('called1')

        this.user = await Moralis.Web3.authenticate()
                      console.log('called')

        this.client = new Web3(window.web3.currentProvider)
        this.address = this.user.get('ethAddress')

        setInterval(() => {
          that.client.eth.getBalance(that.address).then((data) => {
            that.balance = data
          })
          that.client.eth.getBlockNumber().then((block_number) => {
            that.currentBlock = block_number
          })
        }, 3000)
      },
      connectMetamask: function () {
        let that = this
        window.ethereum.request({
            method: 'eth_requestAccounts'
          })
          .then((address) => {

            that.address = address[0]
            that.client = new Web3(window.web3.currentProvider)
            that.client.eth.getBalance(that.address).then((data) => {
              that.balance = data
            })
            window.localStorage.setItem('wallet', 'metamask')
            setInterval(() => {
              that.client.eth.getBlockNumber().then((block_number) => {
                that.currentBlock = block_number
              })
            }, 3000)
          })
          .catch(() => {


          });
      },
    },
    mounted: function () {
      this.client = new Web3('https://speedy-nodes-nyc.moralis.io/efff274f1c666e31a9ad881b/bsc/mainnet')
      this.hotPair()
      this.getBNBPrice()
      this.connectMetamask()
      // let wallet = window.localStorage.getItem('wallet')


    }
  }
</script>