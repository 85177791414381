<template>
    <div v-if="$parent.address != null">
        <div class="modal" tabindex="-1" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog bg-dark" style="border-radius: 12px;">
            <div class="modal-content bg-dark" style="border-radius: 12px;">
            <div class="modal-body bg-dark text-white border-dark" id="percentage" style="border-radius: 12px;">
                <div class="row">
                    <div class="col-md-4">
                        <center>
                            <img onerror="this.src = '/token.webp';"
                                        :src="`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${$parent.client.utils.toChecksumAddress(calculate.token)}/logo.png`"
                                        height="100" class="rounded-circle me-1">
                        </center>
                        <br/>
                        <h4 class="fw-bold mt-2">{{ calculate.name }}</h4>
                    </div>
                    <div class="col-md-8">
                        <small class="text-white">My Returns</small>
                        <h1 :class="{ 'text-profit': calculate.percentage > 0, 'text-rekt': calculate.percentage  < 0}">{{ calculate.percentage == null ? 'Loading' : `${calculate.percentage}%` }}</h1>
                        <small class="text-muted" style="position: absolute; bottom: 10px; right: 10px;">calculated by: fliper.io</small>
                    </div>
                </div>
            </div>
            <div class="modal-footer bg-white">
                <button type="button" class="btn btn-secondary" @click="modal.hide()"><i class="fa fa-times" /></button>
                <button type="button" class="btn btn-primary" @click="share"><i class="fa fa-download"/></button>
            </div>
            </div>
        </div>
        </div>
        <div>
        <h2 class="text-white">My Wallet</h2>
            
            <div class="row mt-5">
                <div class="col-md-4">
                    <div class="card" style="background: #013a63;">
                        <div class="card-body text-white">
                            <h4>Portfolio Value</h4>
                            <h1>{{ totalUSD.toFixed(2) }}$</h1>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn mt-2 mb-2" :class="{'btn-dark': show == 'token', 'btn-outline-dark text-white': show != 'token'}"  @click="show = 'token'" style="border-redius: 10px !important;"><i class="fa fa-fire"></i> Token</button>
            <button class="btn ms-1 mt-2 mb-2" :class="{'btn-dark': show == 'nft', 'btn-outline-dark text-white': show != 'nft'}"  @click="show = 'nft'" style="border-redius: 10px !important;"><i class="fa fa-eye"></i> NFT</button>
            <div v-if="show == 'token'">
                <input type="text" class="form-control w-100 bg-transparent text-white mb-2" placeholder="search by name" v-model="search">
                <div class="card mb-1 bg-transparent" @click="$router.push(`/trade/${balance.tokenAddress || '0xe9e7cea3dedca5984780bafc599bd69add087d56'}`)" v-for="balance in searchBalance" :key="balance.tokenAddress">
                    <div class="card-body text-white">
                        <div class="row">
                            <div class="col-md-1 col-4">
                                <img onerror="this.src = '/token.webp';"
                                        :src="`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${$parent.client.utils.toChecksumAddress(balance.tokenAddress)}/logo.png`"
                                        height="48" class="rounded-circle me-1">
                            </div>
                            <div class="col-md-11 col-8">
                                <span class="float-end text-warning">${{ (balance.worth * $parent.BNBPrice).toFixed(2) }}</span>
                                <b>{{ balance.name }}</b> <br/>
                                {{ (balance.balance / 10 ** balance.decimals).toLocaleString() }} ${{ balance.symbol }} 
                            </div>
                            <div class="col-md-7">
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-1" style="background-color: #013a63;" v-if="show == 'token1'">
                <div class="card-body table-responsive text-white" >
                    <h2>Balance</h2>
                    <input type="checkbox" class="me-2" v-model="showHidden">Show Hidden Tokens
                    <input type="text" class="form-control w-25 float-end bg-transparent text-white" placeholder="search by name" v-model="search">
                    <br/>
                    <br/>
                    <table class="table table-hover">
                        <thead  class="text-white">
                            <th>Asset</th>
                            <th>Balance</th>
                            <th>Value</th>
                            <th></th>
                        </thead>
                        <tbody  class="text-white">
                            <tr v-if="balances.length == 0">
                                <td colspan="5" class="text-white" style="border: none !important;">
                                <center>
                                    Gathering Data...
                                </center>
                                </td>
                            </tr>
                            <tr v-for="balance in searchBalance" :key="balance.tokenAddress">
                                <td> <small><img onerror="this.src = '/token.webp';"
                                        :src="`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${$parent.client.utils.toChecksumAddress(balance.tokenAddress)}/logo.png`"
                                        height="25" class="rounded-circle me-1"></small>{{ balance.symbol }} {{ balance.name }}</td>
                                <td>{{ (balance.balance / 10 ** balance.decimals).toFixed(5) }}</td>
                                <td>${{ (balance.worth * $parent.BNBPrice).toFixed(2) }}</td>
                                <td>
                                    <a href="javascript:void(0)" @click="$router.push(`/trade/${balance.tokenAddress || '0xe9e7cea3dedca5984780bafc599bd69add087d56'}`)"><i class="fa fa-exchange-alt"></i></a>
                                    <a href="javascript:void(0)" v-if="balance.tokenAddress" class="ms-2" @click="calculatePercentage(balance.tokenAddress, balance.name, (balance.worth * $parent.BNBPrice))" data-bs-toggle="modal" data-bs-target="#exampleModal"><i class="fa fa-percentage" /></a>
                                    <a href="javascript:void(0)" @click="hideToken(balance.tokenAddress)" class="text-danger ms-2">
                                        <i class="fa fa-eye" v-if="!hiddenTokens[balance.tokenAddress]"/>
                                        <i class="fa fa-eye-slash" v-if="hiddenTokens[balance.tokenAddress]" />
                                    </a>
                            </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-if="show == 'nft'">
                <input type="text" class="form-control w-100 bg-transparent text-white mb-2" placeholder="search by name" v-model="searchNFT">
                <span class="text-white" v-if="!nftLoaded">Gathering Data....</span>
                <span  v-for="(name, address) in NFTGroup" :key="address"> 
                    <button class="btn btn-rounded-pill  mb-2" v-if="name != ''" :class="{'btn-outline-dark text-white': selectedGroup == address, 'btn-dark':  selectedGroup != address}" >{{ name }}</button>
                </span>
                <div class="row">
                    <div class="col-6 col-md-3" v-for="nft in filterNFT" :key="nft.token_id">
                        <div class="card bg-transparent mb-2">
                            <img :src="nft.metadata.image" class="card-img-top" style="height: 300px; width: 100%;">
                            <div class="card-body bg-transparent text-white">
                                <h5 class="card-title">
                                    <small class="text-muted">#{{ nft.token_id }}</small>
                                    <br/>
                                    {{ nft.metadata.name }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid" v-else>
        <ConnectWallet />
    </div>
</template>


<script>
import Uniswap from '../utils/uniswap'
import erc from '../utils/erc'
import domtoimage from 'dom-to-image';
import ConnectWallet from '../components/ConnectWallet.vue'
export default {
    components: {
        ConnectWallet
    },
    data: () => ({
        showHidden: false,
        hiddenTokens: {},
        balances: [],
        show: 'token', // nft
        search: '',
        searchNFT: '',
        address: '',
        selectedGroup: null,
        NFTGroup: {},
        nftLoaded: false,
        calculate: {

        },
        nfts: [],
        interval: null,
        modal: null
    }),
    computed: {
        groupNFT: function () {
            return []
        },
        searchBalance: function () {
            let filterSearch = this.balances.filter(balance => balance.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || balance.symbol.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
            if(!this.showHidden) {
                filterSearch = filterSearch.filter(balance => !this.hiddenTokens[balance.tokenAddress])
            }
            return filterSearch.sort((a, b) => b.worth - a.worth)
        },
        filterNFT: function () {
            let filterSearch = this.nfts.filter(nft => nft.metadata.name.toLowerCase().indexOf(this.searchNFT.toLowerCase()) > -1)
            return filterSearch
        },
        totalUSD: function () { 
            window.balances = this.balances
            let result = this.searchBalance.reduce((a, b) => a + b.worth, 0) * this.$parent.BNBPrice
            return isNaN(result) ? 0: result
        }
    },
    methods: {
        share:  function () {
            domtoimage.toJpeg(document.getElementById('percentage'), { quality: 0.95 })
            .then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = 'result.jpeg';
                link.href = dataUrl;
                link.click();
            });
        },
        calculatePercentage: async function (address, name, current) {
            this.calculate = {
                'token': address,
                'name': name,
                'percentage': null
            }
            this.$forceUpdate()
            this.modal.show()

            let result = await this.axios.get(`/analytic/calculate_profit/${address}/${this.$parent.address}`)
            let data = result.data
            let initialInvestment = Math.abs(data.initialInvestment)
      
            this.calculate['percentage'] = ((current - initialInvestment) / initialInvestment * 100).toFixed(2)
            this.$forceUpdate()
            
            return 
        },
        hideToken: async function (address) {
            if(this.hiddenTokens[address]) {
                let prevData =  JSON.parse(localStorage.getItem('hiddenTokens')) || {}
                delete prevData[address]
                localStorage.setItem('hiddenTokens', JSON.stringify(prevData))
                this.hiddenTokens = prevData
                return;
            } 
            let prevData =  JSON.parse(localStorage.getItem('hiddenTokens')) || {}
            prevData[address] = true
            localStorage.setItem('hiddenTokens', JSON.stringify(prevData))
            
            this.hiddenTokens = prevData

        },
        getTokenPrice: async function () {
            let that = this
            this.balances.forEach(async function (balance, index) {
                try {
                     if(balance.tokenAddress != undefined) {
                        that.balances[index].balance = await erc.balance(balance.tokenAddress,that.$parent.address,that.$parent.client)
                        let swabPrice = await Uniswap.getSwapPrice(balance.tokenAddress, that.balances[index].balance, that.$parent.client)
                        that.balances[index].worth = Number.parseInt(swabPrice[1]) / 1e18
                  
                    } else {
                        that.balances[index].balance = await that.$parent.client.eth.getBalance(that.$parent.address)
                        that.balances[index].worth =  that.balances[index].balance / 1e18
                    }
                } catch (error) {
                    console.log()
                }
               
            })
        },
        getNFT: async function () {
            let balances = await this.moralis.Web3.getNFTs({ chain: 'bsc', address: this.$parent.address });
            this.nftLoaded = true;
            this.$forceUpdate()
            let that = this
            balances.forEach(async function (nft) {
                if(nft.token_uri == null) return;
                nft.metadata = await (await fetch(nft.token_uri)).json()

                that.NFTGroup[nft.token_address] = nft.name
                that.nfts.push(nft)
                that.$forceUpdate()
            })
        },
        getERC: async function () {
            let balances = await this.moralis.Web3.getAllERC20({ chain: 'bsc', address: this.$parent.address });
            balances.forEach(function (balance, index) {
                balances[index].prices = 0
                balances[index].worth = 0
            })
            this.balances = balances
            this.getTokenPrice()
        },
        getMyTokens: async function () {
            if (this.$parent.address == null) return;
            this.walletLoaded = false;
            let res = await (await fetch(`${this.API}bsc/wallet/${this.$parent.address}`)).json()
            this.myTokens = res.tokens
            this.interval = setInterval(this.balanceWorth, 2500)
        },
        init: async function() {
             if(this.$parent.address == null) {
                 setTimeout(this.init, 1000)
                return;
             }
            this.nftLoaded = false;
            this.address = this.$parent.$parent.address
            this.getERC()
            let hiddenTokens = localStorage.getItem('hiddenTokens')
            if(hiddenTokens == null) {  
                this.hiddenTokens = {}
            } else {
                this.hiddenTokens = JSON.parse(hiddenTokens)
            }
            this.modal = new window.bootstrap.Modal(document.getElementById('exampleModal'), {})
            this.getERC()
            this.getNFT()
        }
    },
    destroyed: function () {
        clearInterval(this.interval)
    },
    mounted: function() {
       this.init()
        document.title = "My Portfolio | fliper.io"
        

    }
}
</script>