const networkDefault = 57
const network = {
    '57': {
        'name': 'BSC',
        'usd': '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        'moralisChain': 'bsc',
        'market': [
            {
                'name': 'Pancake Swap V2',
                'router': '0x10ed43c718714eb63d5aa57b78b54704e256024e',
                'factory': '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73'
            },
            {
                'name': 'Apes Swap',
                'router': '0xcf0febd3f17cef5b47b0cd257acf6025c5bff3b7',
                'factory': '0x0841BD0B734E4F5853f0dD8d7Ea041c241fb0Da6'
            },
            {
                'name': 'Baby Swap',
                'router': '0x325E343f1dE602396E256B67eFd1F61C3A6B38Bd', 
                'factory': '0x86407bEa2078ea5f5EB5A52B2caA963bC1F889Da'
            }
        ]
    }
}
function getFactoryName(address) {
    let mark = network[networkDefault].market.filter(market => market.factory == address)
    if(mark.length == 0) {
        return "Unknown"
    }
    return mark[0].name
}

export default {
    ...network,
    getFactoryName: getFactoryName,
}