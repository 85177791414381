<template>
    <div>
        <div class="card  mt-2" style="background-color: black;">
            <div class="card-body text-white" style="background-color: black;">
                <h5>Basic Detail</h5>
                <p>
                    Token Name: {} <br/>
                    Token Symbol: {} <br/>
                    Token Owner: {} <br/>
                </p>
            </div>
        </div>
        <div class="card mt-2" style="background-color: black;">
            <div class="card-body text-white" >
                <h5>Trade Analytic</h5>
                <h2 class="text-success">Token is Tradeable</h2>
            </div>
        </div>
        <div class="card  mt-2" style="background-color: black;">
            <div class="card-body text-white" style="background-color: black;">
                <h5>Market</h5>
                <div class="card mt-2 border-0">
                        <div class="card-body text-white" style="background-color: black;">
                        <h6>Apeswap</h6>
                        Liquidity: 1 BNB
                        <hr/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {

}
</script>