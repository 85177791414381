<template>
    <div :key="trade">
        <div class="row">
            <div class="col-md-3">
                <input class="form-control mb-2 shadow bg-transparent text-white" placeholder="Token name, address" @keyup="searchTrigger" v-model="search" data-bs-toggle="dropdown" />
                <ul class="dropdown-menu" style="cursor:pointer;" aria-labelledby="dropdownMenuButton1">
                    <li v-for="helper in helpers" :key="helper.address" @click="$router.push('/trade/' + helper.address)">
                        <a class="dropdown-item">
                        <img onerror="this.src = '/token.webp';"
                                        :src="`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${$parent.client.utils.toChecksumAddress(helper.address)}/logo.png`"
                                        height="25" class="rounded-circle me-1">
                        {{ helper.name }}</a></li>
                </ul>
            </div>
        </div>
        <div class="row">
            
            <div class="col-md-12" v-if="selectedPair == null || token == null">
                <div>
                    
                    <center class="mt-5">
                        <div class="spinner-grow text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <h5 class="text-muted">Loading</h5>
                    </center>
                </div>
            </div>
            <div class="col-md-12" v-if="selectedPair != null && token != null">
                  <div class="  mt-2 mb-2" style="background-color: transparent;">
                    <div class=" text-white" style="background-color: transparent;">
                        <div class="row mb-2">
                            <div class="col-md-4" style="display: flex;">
                                <img onerror="this.src = '/token.webp';"
                                        :src="`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${$parent.client.utils.toChecksumAddress($router.currentRoute.params.token)}/logo.png`"
                                        height="52" class="rounded-circle me-1" style="display: inline-block;">

                                <h5 class="ms-2">{{ token.name }}
                                  <h4 class="text-warning">{{ priceUSD() }}$</h4>
                                </h5>
                            </div>
                  
                            <div class="col-md-2 col-6">
                                <h6 class="text-muted">Liquidity  </h6>
                                <h6 class="text-warning">{{ token.total_liquidity.toLocaleString() }}$</h6>
                            </div>
                            <div class="col-md-2">
                                <h6 class="text-muted">Fully Diluted Marketcap</h6>
                                <h6 class="text-warning">{{ ((token.totalSupply / 10 ** token.decimals) * token.priceUSD).toLocaleString() }}$</h6>
                            </div>
                            <div class="col-md-2">
                                <button class="btn btn-light float-end  w-100" v-if="!isWhitelisted" @click="addWhitelist"><i class="fa fa-eye"></i> Add Watchlist</button>
                                <button class="btn btn-danger float-end  w-100" v-if="isWhitelisted" @click="removeWhitelist"><i class="fa fa-eye"></i> Remove Watchlist</button>
                                <button class="btn btn-warning float-end mt-2 w-100" @click="addMetamask"><img src="@/assets/metamask.svg" height="24" width="24"> Add Metamask</button>
                            </div>
                        </div>
                        <a class="btn btn-dark btn-sm  me-1 mb-1"  target="_blank" rel="noopener" :href="`https://bscscan.com/token/${token.address}`"><i class="fa fa-globe"></i> BSCScan</a>
                        <a class="btn btn-dark  me-1  btn-sm  mb-1" v-if="token.additional && token.additional.telegram" :href="`https://t.me/${token.additional.telegram}`" target="_blank" rel="noopener"><i class="fab fa-telegram"></i> Telegram</a>
                        <a class="btn btn-dark  me-1  btn-sm  mb-1" v-if="token.additional && token.additional.twitter" :href="`https://twitter.com/${token.additional.twitter}`"  target="_blank" rel="noopener"><i class="fab fa-twitter"></i> Twitter</a>
                        <a class="btn btn-dark  me-1  btn-sm  mb-1" v-if="token.additional && token.additional.facebook" :href="`https://facebook.com/${token.additional.facebook}`"  target="_blank" rel="noopener"><i class="fab fa-facebook"></i> Facebook</a>
                        <a class="btn btn-dark  me-1  btn-sm  mb-1" v-if="token.additional && token.additional.site" :href="`${token.additional.site}`"  target="_blank" rel="noopener"><i class="fa fa-globe"></i> Website</a>

                        <br/>
                        <br/>
                        <h6 class="fw-bold">Key Metrics</h6>
                        <div class="badge bg-dark shadow me-1" v-if="token.tradeable"><i class="fa fa-check"></i> Tradeable</div>
                        <div class="badge bg-danger  shadow me-1"  v-if="!token.tradeable"><i class="fa fa-exclamation"></i> Not Tradeable</div>
                        <div class="badge bg-dark  me-1 " v-if="!hasOwner"><i class="fa fa-check"></i> Owner Burned Or No Owner</div>
                        <div class="badge bg-warning   me-1 " v-if="hasOwner"><i class="fa fa-exclamation"></i> Owner Control</div>
                        <div class="badge bg-dark shadow  me-1 " v-if="token.contract_verified"><i class="fa fa-check"></i> Smart Contract Verified</div>
                        <div class="badge bg-danger   me-1 "  v-if="!token.contract_verified"><i class="fa fa-exclamation"></i> Smart Contract Not Verified</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-2 row">
            <div class="col-md-8">
                <select class="form-control w-25" v-model="selectedPair" @change="handlePairChange">
                    <option v-for="pair in token.pairs" :key="pair.address" :value="pair.address">{{ pair.token0.symbol }} / {{ pair.token1.symbol }}</option>
                </select>
            </div>
        </div>
        <div class="row mt-2" :class="{'d-none': (selectedPair == null || token == null) }">
                        <div class="col-md-9" v-if="selectedPair != null">
                 
                <Chart :pair="selectedPair" :key="`chart${chart}`" :token="token" />
                <br />
                <br />
                
            </div>
            <div class="col-md-3 d-none d-md-block">

                <Swap :address="$router.currentRoute.params.token" v-on:pairChange="handlePairChange"
                    v-on:noPair="handleNoPair" :class="{'d-none': selectedPair == null}" />
            </div>
            <div class="col-md-12">
                <b-button-group>
                    <button class="btn btn-outline-light" @click="bottomOption = 'all';"
                        :class="{ 'btn-light text-dark': bottomOption == 'all'}">All Tx</button>
                       <button class="btn btn-outline-light d-block d-md-none" @click="bottomOption = 'swap';"
                        :class="{ 'btn-light  text-dark': bottomOption == 'swap'}">Swap</button>
                    <button class="btn btn-outline-light" @click="bottomOption = 'my'; "
                        :class="{ 'btn-light text-dark': bottomOption == 'my'}">My Tx</button>
                    <button class="btn btn-outline-light" @click="bottomOption = 'liquidity'; "
                        :class="{ 'btn-light text-dark': bottomOption == 'liquidity'}">Liquidity</button>
        

                 
                </b-button-group>
                <TableSwap :key="`all${all}`" class="mt-2" v-if="bottomOption == 'all' && selectedPair != null && token != null" :token="token" />
                <TableSwap :key="`wallet${wallet}`" class="mt-2" v-if="bottomOption == 'my' && selectedPair != null && token != null" :token="token"
                    :address="`${$parent.address}`" :currentPrice="uniswap.priceNative * $parent.BNBPrice" />
                <TokenInfo v-if="bottomOption == 'info'" />
                <Swap :address="$router.currentRoute.params.token" v-if="bottomOption == 'swap'" class="mt-3"  />
                <div v-if="bottomOption == 'liquidity'">
                <div class="row">
                    <div class="col-md-4" v-for="pair in token.pairs" :key="pair.address">
                    <div class="card mt-2" style="background-color: #013a63;">
                        <div class="card-body text-white">
                            <center>
                            <img onerror="this.src = '/token.webp';"
                                        :src="`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${$parent.client.utils.toChecksumAddress(pair.token0.address)}/logo.png`"
                                        height="42" class="rounded-circle me-1" style="display: inline-block;">
                            <img onerror="this.src = '/token.webp';"
                                        :src="`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${$parent.client.utils.toChecksumAddress(pair.token1.address)}/logo.png`"
                                        height="42" class="rounded-circle me-1" style="display: inline-block;">
                            <h2 class="fw-bold">{{ getFactoryName(pair.factory) }}</h2>
                            <h5 class="mt-2">{{ pair.token0.symbol }} / {{ pair.token1.symbol }}</h5>
                            <h6 class="mt-2 text-warning">{{ (pair.token0.balance / 10 ** pair.token0.decimals).toLocaleString() }} / {{ (pair.token1.balance / 10 ** pair.token1.decimals).toLocaleString() }}</h6>
                            <h4>{{ pair.worth.toLocaleString() }} $</h4>
                            </center>
                        </div>
                    </div>
                </div>
                </div>
                </div>
            </div>
        </div>
        <!-- <div class="row" v-if="nopair">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <center>
            <form @submit="$router.push(`/trade/${address}`)">
            <h1>No Pair Was Found</h1>
            <h4>Try <a href="javascript:void(0)" @click="$router.push(`/sniper`)">Snipe It </a> or view other token</h4>
            <input class="form-control rounded-pill" style="height: 50px;" v-model="address" placeholder="Token Address">
            </form>
          </center>
        </div>
        <div class="col-md-2"></div>
      </div> -->
    </div>
</template>


<script>
    import Chart from '../components/Chart.vue'
    import Swap from '../components/Swap.vue'
    import TableSwap from '../components/TableSwap.vue'
    import TokenInfo from '../components/TokenInfo.vue'
    import Uniswap from '../utils/uniswap'
    import Network from '../utils/network'
    
    export default {
        components: {
            Chart,
            Swap,
            TableSwap,
            TokenInfo
        },
        data: () => ({
            'selectedPair': null,
            'nopair': false,
            'loaded': false,
            'bottomOption': 'liquidity',
            'search': "",
            'helpers': [],
            chart: 1,
            all: 1,
            wallet: 1,
            trade: 0,
            interval: null,
            token: null,
            isWhitelisted: false,
            uniswap: {
                priceNative: 0,
            }
        }),
        watch: {
            '$route': function(){
                this.trade = !this.trade
            }

        },
        computed: {
            
        },
        methods: {
            getFactoryName: function (address) {
                return Network.getFactoryName(address)
            },
            addWhitelist: async function () {
                var whitelists = window.localStorage.getItem("whitelist")
                if(whitelists == null) {
                    whitelists = {
                        [this.token.address]: {
                            name: this.token.name,
                            symbol: this.token.symbol,
                            decimals: this.token.decimals,
                        }
                    }
                } else {
                    whitelists = JSON.parse(whitelists)
                    whitelists[this.token.address] = {
                            name: this.token.name,
                            symbol: this.token.symbol,
                            decimals: this.token.decimals,
                        }
                }
                window.localStorage.setItem("whitelist", JSON.stringify(whitelists))
                this.loadWhitelist()
            },
            removeWhitelist: function() {
                var whitelists = window.localStorage.getItem("whitelist")
                if(whitelists != null) {
                    whitelists = JSON.parse(whitelists)
                    if(this.token.address in whitelists) {
                        delete whitelists[this.token.address]
                    }
                }
                window.localStorage.setItem("whitelist", JSON.stringify(whitelists))
                this.loadWhitelist()
            },
            loadWhitelist: function () {
                var whitelists = window.localStorage.getItem("whitelist")
                this.isWhitelisted = false;
                if(whitelists != null) {
                    whitelists = JSON.parse(whitelists)
                    if(this.token.address in whitelists) {
                        this.isWhitelisted = true;
                    }
                }
            },
            addMetamask: async function () {
                await window.ethereum.request({
                    method: 'wallet_watchAsset',
                    params: {
                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                    options: {
                        address: this.token.address, // The address that the token is at.
                        symbol: this.token.symbol, // A ticker symbol or shorthand, up to 5 chars.
                        decimals: this.token.decimals, // The number of decimals in the token
                        image: `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${this.$parent.client.utils.toChecksumAddress(this.token.address)}/logo.png`, // A string url of the token logo
                    },
                    },
                });


            },
            hasOwner: function () {
                if(this.token.owner == "0x0000000000000000000000000000000000000000") return false;
                if(this.token.owner.length < 5) return false;
                return true;
            },
            priceUSD: function () {
       
                if('priceUSD' in this.token) {
                    return this.token.priceUSD.toLocaleString(undefined, {minimumFractionDigits: 10})
                } else {
                    return 'loading';
                }
            },
            getBNBPrice: async function () { 
                try {
                    this.token.priceNative = await Uniswap.getTokenPriceNative(this.$router.currentRoute.params.token, this.$parent.client)
                    this.token.priceUSD = this.token.priceNative * this.$parent.BNBPrice
                    document.title = `${this.token.name} Market 💲 ${this.token.priceUSD.toLocaleString(undefined, {minimumFractionDigits: 10})}`
                } catch (error) {
                    console.log(error)
                }
                this.$forceUpdate()
            },
            searchTrigger: function () {
                let current = this.search
                let that = this
                setTimeout(() => {
                    console.log(current, that.search)
                    if(current == that.search) this.searchAutocomplete()
                }, 1000)
            },
            searchAutocomplete: async function () {
                this.helpers  = []
                if(new RegExp(/0x[a-fA-F0-9]{40}/g).test(this.search)) {
                    let token = (await this.axios.get(`/token/${this.search}`)).data
                    this.helpers.push({
                        'address': this.search,
                        'name': token.name,

                    })
                    return;
                } 
                let searchs = (await this.axios.get(`/search_token?q=${this.search}`, {mode: 'cors'})).data.data
                this.helpers = searchs
            },
            handleNoPair: function () {
                this.nopair = true
            },
            handlePairChange: function () {
                clearInterval(this.interval)
                this.chart = this.chart + 1;
                this.all = this.all + 1;
                this.wallet = this.wallet + 1;
                this.interval = setInterval(this.getBNBPrice, 3000)
                this.getBNBPrice()
            },
            getTokenInfo: async function () {
                this.token = (await this.axios.get(`/token/${this.$router.currentRoute.params.token}`, {mode: 'cors'})).data
                this.token.address = this.$router.currentRoute.params.token
                this.selectedPair = this.token.pairs[0].address
                this.loadWhitelist()
                this.$forceUpdate()
            }

        },
        destroyed: function () {
            clearInterval(this.interval)
        },
        mounted: async function () {
            this.getTokenInfo();
            document.title = `Fliper`
            
        }
    }
</script>