<template>
    <div v-if="$parent.address != null">
        <h2 class="text-white">My Swap History</h2>
        <div v-if="$parent.address != null">
            <div class="card mt-5"  style="background-color: #013a63;"> 
                <div class="card-body table-responsive">
                    <input type="text" class="form-control w-25 float-end" placeholder="search by name" v-model="search">
                    <br/>
                    <br/>
                    <table class="table table-hover">
                        <thead  class="text-white">
                            <th>Token</th>
                            <th>Token</th>
                            <th>Action</th>
                            <th>Time</th>
                            <th>Price</th>
                            <th></th>

                        </thead>
                        <tbody  class="text-white">
                            <tr v-if="searched.length == 0">
                                <td colspan="5" style="border: none !important;">
                                <center>
                                    Gathering Data...
                                </center>
                                </td>
                            </tr>
                            <tr v-for="history in searched" :key="history.logId">
                                <td >
                                    <img onerror="this.src = '/token.webp';" :src="`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${$parent.client.utils.toChecksumAddress(history.pair.token0.address)}/logo.png`" height="25" class="rounded-circle me-1"><br/>
                                    ({{ history.pair.token0.symbol }})
                                    
                                </td>
                                <td>
                                    <img onerror="this.src = '/token.webp';" :src="`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${$parent.client.utils.toChecksumAddress(history.pair.token1.address)}/logo.png`" height="25" class="rounded-circle me-1"> <br/>
                                    ({{ history.pair.token1.symbol }})
                                </td>
                                <td>
                                    Swap
                                    <span v-if="history.amount0Out != 0">{{ (history.amount1In / 10 ** parseInt(history.pair.token1.decimals)).toLocaleString() }} {{ history.pair.token1.name }} To  {{ (history.amount0Out / 10 ** parseInt(history.pair.token0.decimals)).toLocaleString() }} {{ history.pair.token0.name }}</span>
                                    <span v-if="history.amount1Out != 0">{{ (history.amount0In / 10 ** parseInt(history.pair.token0.decimals)).toLocaleString() }} {{ history.pair.token0.name }} To {{ (history.amount1Out / 10 ** parseInt(history.pair.token1.decimals)).toLocaleString() }} {{ history.pair.token1.name }}</span>
                                </td>
                                <td> {{ ago(history.timestamp * 1000) }}</td>
                                <td>{{ usdValue(history) }}$</td> 
                                <td>
                                    <a href="javascript:void(0)" @click="trade(history)" class="me-2 text-white"><i class="fa fa-exchange-alt"></i></a>
                                    <a :href="`https://bscscan.com/tx/${history.transactionHash}`" target="_blank" class="text-white" rel="noopener"><i class="fa fa-search"></i></a></td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid" v-else>
        <ConnectWallet />
    </div>
</template>


<script>
import Uniswap from '../utils/uniswap'
// import erc from '../utils/erc'
import moment from 'moment'
import ConnectWallet from '../components/ConnectWallet.vue'
export default {
    components: {
        ConnectWallet
    },
    data: () => ({
        showHidden: false,
        hiddenTokens: {},
        history: [],
        pairs: {},
        search: ''
    }),
    computed: {
        searched: function () {
            let filterSearch = this.history
            if(this.search != '') {
                filterSearch = filterSearch.filter((v) => v.pair.token0.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || v.pair.token1.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || v.pair.token0.symbol.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || v.pair.token1.symbol.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
            }
            return filterSearch.sort((a,b) =>   b.timestamp - a.timestamp)
        }
    },
    methods: {
        usdValue: function (swap) {
            let decimalsLeft = 0
            if(swap.pair.token0.symbol == "WBNB") {
                decimalsLeft = swap.pair.token0.decimals - swap.pair.token1.decimals
            } else {
                decimalsLeft = swap.pair.token1.decimals - swap.pair.token0.decimals
            }
            let usd = swap.usd0
            
            if(swap.pair.token0.address == "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c") {
                usd =  swap.usd1
            }
             let usdReal = (usd / ( 10 ** decimalsLeft))
             return  (usdReal).toFixed((usdReal > 1 ? 2 : 16))
        },
        trade: function (history) {
            this.$router.push(`/trade/${(history.pair.token0.address == "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" ? history.pair.token1.address: history.pair.token0.address)}`)
        },
        ago: function (time) {
            return moment(time).fromNow()
        },
        getAdditional: async function () {
            let pairs = {}
            let that = this
            this.history.forEach(function (history) {
                pairs[history.address] = true
            })
            Object.keys(pairs).forEach(async function (index) {
                that.pairs[index] = await Uniswap.getPairInfo(index, that.$parent.client)
                that.$forceUpdate()
            })
        },
        getHistory: async function () {
            if (this.$parent.address == null) {
                setTimeout(this.getHistory, 500)
                return;
            }
            this.walletLoaded = false;
            let res = await this.axios.get(`/wallet/${this.$parent.address}/history`)
            this.history = res.data.data
        }
        
    },
    mounted: function() {
        this.getHistory()
        document.title = "My Trade History | fliper.io"
    }
}
</script>