import ABI from './abi'

const info = async function (tokenAddress, web3) {
    let TOKEN = new web3.eth.Contract(ABI.ERC_ABI, tokenAddress)
    let result = await Promise.all([
        TOKEN.methods.name().call(),
        TOKEN.methods.symbol().call(),
        TOKEN.methods.decimals().call(),
        TOKEN.methods.totalSupply().call(),
    ])
    return result

}
const balance = async function (tokenAddress, address, web3) {
    let TOKEN = new web3.eth.Contract(ABI.ERC_ABI, tokenAddress)
    return await TOKEN.methods.balanceOf(address).call()

}
const contract = function (tokenAddress, web3) {
    return new web3.eth.Contract(ABI.ERC_ABI, tokenAddress)
}

export default {
    info,
    balance,
    contract,
}