import ABI from './abi'
import network from './network'
import erc from './erc'
const STABLE = ['0xe9e7cea3dedca5984780bafc599bd69add087d56', '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d', '0x23396cf899ca06c4472205fc903bdb4de249d6fc']
const getTokenPriceNative = async function (tokenAddress, web3) {
  try {
    let FACTORY = new web3.eth.Contract(ABI.FACTORY_ABI, '0xca143ce32fe78f1f7019d7d551a6402fc5350c73')
    let pairAddress = await FACTORY.methods.getPair('0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', tokenAddress).call()
    let PAIR = new web3.eth.Contract(ABI.PAIR_ABI, pairAddress)
    let [token0,token1] = await Promise.all([PAIR.methods.token0().call(),PAIR.methods.token1().call()])
    let token0Contract = new web3.eth.Contract(ABI.ERC_ABI, token0)
    let token1Contract = new web3.eth.Contract(ABI.ERC_ABI, token1)
    let [decimals0, decimals1] = await Promise.all([token0Contract.methods.decimals().call(), token1Contract.methods.decimals().call()])
    let reserves = await PAIR.methods.getReserves().call()
    let priceNow = [
      Number.parseInt(reserves._reserve0) / Number.parseInt(reserves._reserve1),
      Number.parseInt(reserves._reserve1) / Number.parseInt(reserves._reserve0)
    ]
    let price  = (token0 == '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c' ? priceNow[0] : priceNow[1])
    let decimalsLeft = 0
    if(token0 == '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c') {
      decimalsLeft = decimals0 - decimals1
    } else {
      decimalsLeft = decimals1 - decimals0
    }
    console.log(decimalsLeft)
    return price / ( 10 ** decimalsLeft )
  } catch (error) {
    return 0;
  }
    

}
const getPairs = async function (tokenAddress, web3) {
  let pairs = []
  for (let i = 0; i < network[57].market.length; i++) {
    try {
      let FACTORY = new web3.eth.Contract(ABI.FACTORY_ABI, network[57].market[i].factory)
      for(let i in STABLE) {
        let pairAddress = await FACTORY.methods.getPair(STABLE[i], tokenAddress).call()
        if(pairAddress != "0x0000000000000000000000000000000000000000") {
          pairs.push({
            ...network[57].market[i],
            'pair': pairAddress
          })
        }
      }
      let pairAddress = await FACTORY.methods.getPair('0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', tokenAddress).call()
      if(pairAddress != "0x0000000000000000000000000000000000000000") {
        pairs.push({
          ...network[57].market[i],
          'pair': pairAddress
        })
      }
    } catch (e) {
      console.log(e)
    }
  }
  return pairs
}
const getPairInfo = async function (pairAddress, web3) {
  let pair = new web3.eth.Contract(ABI.PAIR_ABI, pairAddress)
  let [token0, token1] = await Promise.all([pair.methods.token0().call(), pair.methods.token1().call()])
  let [token0Detail, token1Detail] = await Promise.all([erc.info(token0, web3), erc.info(token1, web3)])
  return [token0Detail, token1Detail, token0, token1]
}
const getSwapPrice = async function (tokenAddress, balance, web3) {
    try {
      let ROUTER = new web3.eth.Contract(ABI.ROUTER_ABI, '0x10ed43c718714eb63d5aa57b78b54704e256024e')
      let amountsOut = await ROUTER.methods.getAmountsOut(balance, [tokenAddress, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c']).call()
      return amountsOut
    } catch (error) {
      return [0,0]
    }
    
}
const getNativePrice = async function (web3) {
  try {
    let FACTORY = new web3.eth.Contract(ABI.FACTORY_ABI, '0xca143ce32fe78f1f7019d7d551a6402fc5350c73')
    let pairAddress = await FACTORY.methods.getPair('0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', '0xe9e7cea3dedca5984780bafc599bd69add087d56').call()
    let PAIR = new web3.eth.Contract(ABI.PAIR_ABI, pairAddress)
    let [token0] = await Promise.all([PAIR.methods.token0().call()])

    let reserves = await PAIR.methods.getReserves().call()
    let priceNow = [
      Number.parseInt(reserves._reserve0) / Number.parseInt(reserves._reserve1),
      Number.parseInt(reserves._reserve1) / Number.parseInt(reserves._reserve0)
    ]
    return (token0 == '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c' ? priceNow[1] : priceNow[0])
  } catch(e) {
    return 0
  }
   
}
class Uniswap {
  constructor(factoryAddress, routerAddress, web3) {
    this.FACTORY = new web3.eth.Contract(ABI.FACTORY_ABI, factoryAddress)
    this.ROUTER = new web3.eth.Contract(ABI.ROUTER_ABI, routerAddress)
    this.web3 = web3
  }
  async getNativePrice(tokenAddress) {
    let pairAddress = await this.FACTORY.methods.getPair('0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', tokenAddress).call()
    let PAIR = new this.web3.eth.Contract(ABI.PAIR_ABI, pairAddress)
    let [token0,token1] = await Promise.all([PAIR.methods.token0().call(),PAIR.methods.token1().call()])
    let token0Contract = new this.web3.eth.Contract(ABI.ERC_ABI, token0)
    let token1Contract = new this.web3.eth.Contract(ABI.ERC_ABI, token1)
    let [decimals0, decimals1] = await Promise.all([token0Contract.methods.decimals().call(), token1Contract.methods.decimals().call()])
    let reserves = await PAIR.methods.getReserves().call()
    let priceNow = [
      Number.parseInt(reserves._reserve0) / Number.parseInt(reserves._reserve1),
      Number.parseInt(reserves._reserve1) / Number.parseInt(reserves._reserve0)
    ]
    let price  = (token0 == '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c' ? priceNow[0] : priceNow[1])
    let decimalsLeft = 0
    if(token0 == '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c') {
      decimalsLeft = decimals0 - decimals1
    } else {
      decimalsLeft = decimals1 - decimals0
    }
    console.log(decimalsLeft)
    return price / ( 10 ** decimalsLeft )
  }
  async getSwapPrice(tokenAddress, balance) {
    let amountsOut = await this.ROUTER.methods.getAmountsOut(balance, [tokenAddress, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c']).call()
    return amountsOut
  }
}

const router =  function (routerAddress, web3) {
  return new web3.eth.Contract(ABI.ROUTER, routerAddress)
}

export default  {
    getTokenPriceNative,
    getNativePrice,
    getSwapPrice,
    getPairs,
    router,
    getPairInfo,
    Uniswap
}